import React from 'react';
import { graphql } from 'gatsby';
import Link from '@sentry/static-shared/components/link';
import styled from '@emotion/styled';
import { deepLinkHeaderCompensationMixin } from '@sentry/static-shared/utils/css';
import { colorText } from '@sentry/static-shared/utils/css/colors';
import Layout from './layout';
import {
  FlexGrid,
  FlexGridCell,
} from '@sentry/static-shared/components/FlexGrid';
import Layer from '@sentry/static-shared/components/layer-next';
import Line from '@sentry/static-shared/components/Line';
import Button from '@sentry/static-shared/components/Button';
import SearchLayer from '../search-layer';
import CustomMDXRenderer from '@sentry/static-shared/components/CustomMDXRenderer';
import LinkListArticles from '@sentry/static-shared/components/link-list-articles';
import { mqMin } from '@sentry/static-shared/utils/css';
import { useLocation } from "@reach/router";
import { canonicalURL } from '@sentry/static-shared/utils/CanonicalLink';

const CategoryLayout = ({ data }) => {
  const { title, excerpt, body, thumbnail, sections } = data.category;

  if (!sections) {
    throw new Error(`Sections found in category "${title}". Please remove it.`);
  }

  const locationSlug = useLocation();
  const canonicalLink = canonicalURL('help', locationSlug.pathname);

  return (
    <Layout
      seo={{
        title,
        description:
          excerpt || `Article list index for the category “${title}”`,
        canonical: canonicalLink,
      }}
    >
      <SearchLayer />
      <StyledLayer>
        <StyledDivBreadcrumbs>
          <Link to="/">Help Center</Link> / {title}
        </StyledDivBreadcrumbs>

        <FlexGrid alignItems="center">
          <FlexGridCell xs={2}>
            <StyledImg key={thumbnail.id} src={thumbnail.publicURL} />
          </FlexGridCell>
          <FlexGridCell xs={9}>
            <StyledTitleHeader>{title}</StyledTitleHeader>
            <CustomMDXRenderer body={body} />
          </FlexGridCell>
        </FlexGrid>
        <FlexGrid justifyContent="center">
          <FlexGridCell md={9}>
            <StyledULMain>
              {sections.map(({ title, id, slug, articles }) => {
                if (!articles) {
                  throw new Error(
                    `No articles found for section "${title}" in category "${data.category.title}". Please remove it.`
                  );
                }

                return (
                  <li key={id} id={slug}>
                    <StyledLine variant="squiggle" />
                    <h3>{title}</h3>
                    <LinkListArticles>
                      {articles.map(({ title, id, url }) => {
                        return (
                          <li key={id}>
                            <Link to={url}>{title}</Link>
                          </li>
                        );
                      })}
                    </LinkListArticles>
                  </li>
                );
              })}
            </StyledULMain>
          </FlexGridCell>
          <StyledFlexGridCell md={3}>
            <StyledULSidebar>
              {sections.map(({ title, id, slug }) => {
                return (
                  <li key={id}>
                    <Button variant="silent" to={'#' + slug}>
                      {title}
                    </Button>
                  </li>
                );
              })}
            </StyledULSidebar>
          </StyledFlexGridCell>
        </FlexGrid>
      </StyledLayer>
    </Layout>
  );
};

export default CategoryLayout;

const StyledImg = styled.img`
  width: 100%;
  margin: 1rem 0;
`;

const StyledDivBreadcrumbs = styled.div`
  margin-bottom: 0;

  a {
    transition: color 0.2s;

    &:hover {
      color: ${colorText};
      text-decoration: none;
    }
  }
`;

const StyledTitleHeader = styled.h1`
  margin: 1rem 0 0;
`;

const StyledULMain = styled.ul`
  list-style: none;
  padding: 0;

  > li {
    margin-bottom: 4rem;
    ${deepLinkHeaderCompensationMixin}
  }
`;

const StyledLine = styled(Line)`
  margin-bottom: 2rem;
`;

const StyledFlexGridCell = styled(FlexGridCell)`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 6rem;
`;

const StyledULSidebar = styled.ul`
  list-style: none;
  padding: 0;

  > li {
    margin-bottom: 0.5rem;
  }
`;

const StyledLayer = styled(Layer)`
  ${mqMin('md')} {
    > div {
      padding-top: 3rem;
    }
  }
`;

export const pageQuery = graphql`
  query CategoryQuery($id: String) {
    category(id: { eq: $id }) {
      title
      body
      thumbnail {
        id
        publicURL
      }
      excerpt(pruneLength: 160)
      sections {
        id
        title
        url
        slug
        articles {
          id
          title
          url
        }
      }
    }
  }
`;
