import React from 'react';
import styled from '@emotion/styled';

import Layer from '@sentry/static-shared/components/layer-next';
import { backgroundChaos } from '@sentry/static-shared/utils/css/patterns';
import { TextAlign } from '@sentry/static-shared/components/Typography';
import Search from '@sentry/static-shared/components/search';

const SearchLayer = () => {
  return (
    <StyledLayer backgroundCSS={backgroundChaos}>
      <TextAlign xs="center">
        <Search
          placeholder="Search support, docs and more"
          config={['zendesk_sentry_articles', 'docs', 'develop', 'blog']}
        />
      </TextAlign>
    </StyledLayer>
  );
};

const StyledLayer = styled(Layer)`
  z-index: 1;
  > div {
    padding: 2rem 2rem 0.5rem;
  }
`;

export default SearchLayer;
